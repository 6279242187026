import imgUrl from "@/utils/imgUrl"

const productList = [
  {
    type: '气体传感器',
    data: [
      {
        id: "001",
        name: "新型纳米气体传感器",
        dec: "新型纳米气体传感器",
        imgUrl: "Nano-gas-sensor.png",
        text: ["功耗远低于市面上的金属氧化物气体传感器, 同时保持了媲美电化学气体传感器的高灵敏度；阵列传感器可实现多种气体的同时监测,克服了传统传感器选择性低与易受干扰性气体及温湿度影响的缺点, 极大降低现有气体传感器的成本。"]
      },  {
        id: "002",
        name: "氢气传感器",
        dec: "氢气传感器",
        imgUrl: "sensor-H2.png",
        text: ["检测极限可以达到10ppb(0.01ppm),超高的灵敏度极大提高氢能生产,运输,储存和应用的安全监控的可靠性,保障企业,居民和用户的安全。理论计算寿命长达10年以上。体积小（可选插件封装或MEMS封装。成本低,抗干扰力强,可根据客户需求定制量程。"]
      },  {
        id: "003",
        name: "TVOC传感器",
        dec: "TVOC传感器",
        imgUrl: "sensor-TVOC.png",
        // href: "http://gd.aisensing.cn/img/guige/TVOC NHC1021 V1.2.pdf",
        text: ["检测气体:甲醛、乙醇等VOC,检测下限 5ppb,检测范围 10ppb~50ppm,工作电压Vc 3.3v,工作功耗40mW（连续）/0.5mw（脉冲）"]
      },  {
        id: "004",
        name: "甲醛传感器",
        dec: "甲醛传感器",
        imgUrl: "sensor-CH2O.png",
        text: ["检测极限可以达到2ppb（0.002ppm),理论计算寿命长达5年以上,体积小（可选插件封装或MEMS封装）,成本大大低于电化学或PID传感器,可根据客户需求定制量程。"]
      },  {
        id: "005",
        name: "臭氧传感器",
        dec: "臭氧传感器",
        imgUrl: "sensor-O3.png",
        // href: "http://gd.aisensing.cn/img/guige/chouyang NHC1011 V1.1.pdf",
        text: ["检测气体:臭氧,检测范围 0~500ppb,工作电压 Vc 3.3V,检测下限 2ppb,工作功耗 60mW。"]
      },  {
        id: "006",
        name: "甲烷传感器",
        dec: "甲烷传感器",
        imgUrl: "sensor-CH4.png",
        // href: "http://gd.aisensing.cn/img/guige/jiawan NHC1022 V1.1.pdf",
        text: ["检测气体:甲烷,丙烷等,检测范围 0~50000ppm,工作电压 Vc 3.3V,检测下限 100ppm,工作功耗 60mW。"]
      }
    ]
  }, {
    type: '传感器模组',
    data: [
      {
        id: "011",
        name: "AGM-003气体传感器模组",
        dec: "AGM-003气体传感器模组",
        imgUrl: "AGM-003.png",
        href: "http://gd.aisensing.cn/img/guige/AGM003.pdf",
        text: [
          "TVOC气体传感器模组，是采用基于高比表面积的纳米结构模板,采用先进的沉积工艺在纳米尺度调控敏感材料的MEMS微型气体传感器开发的模组，于检测环境中TVOC气体含量，进而判别空气质量。还可根据用户需求进行调整或扩展其他气体感传器，比如调整为HCHO、H2、CO、TVOC、CH₄等气体。",
          "模组的特点有具有极高灵敏度、可靠的稳定性、使用便捷、同时具有长寿命、高性价比等特点。主要广泛用于智慧家居，建筑室内外环境监测，车载空气检测、畜牧养殖空气检测和手持空气质量检测仪等。"
        ]
      },  {
        id: "012",
        name: "AGM-001b双芯片气体传感器模组",
        dec: "AGM-001b双芯片气体传感器模组",
        imgUrl: "AGM-001.png",
        href: "http://gd.aisensing.cn/img/guige/AGM001.pdf",
        text: ["外形尺寸:24×20×3.6mm（L×W×H）,检测气体:甲醛、苯、一氧化碳、氢气、酒精、氨气、香烟烟雾、香精等有机挥发气体,工作电压:5.0±0.2VDC（无电压反接保护）,工作电流≤60m,工作温度 -20℃～50℃,工作湿度 ≤96％R,使用寿命10年。"]
      // },  {
      //   id: "013",
      //   name: "SPmodule-001复合接口双通道气体传感器模组",
      //   dec: "SPmodule-001复合接口双通道气体传感器模组",
      //   imgUrl: "SPmodule-001.png",
      //   href: "http://gd.aisensing.cn/img/guige/SPmodule-001.pdf",
      //   text: ["工作电压：+5V~+12V,具有电压反接、短路、过流保护等功能。信号接口：MicroUSB 通讯、TTL 电平、485通信。工作温度：-20～50℃。工作湿度：0～95%。"]
      }
    ]
  }, {
    type: "环境气氛监测系统",
    data: [
      {
        id: "021",
        name: "恶臭在线检测系统",
        dec: "恶臭在线检测系统",
        imgUrl: "hanlan.png",
        text: [
          "采用了恶臭算法以及NHC传感器对气味进行感知、分析、判断，在若干种混合气体杂乱的环境中精确地辨认出各种气体的浓度并进行报警，且具有较好的重复性及灵敏性等特点。",
          "恶臭在线检测系统可同时检测氨、硫化氢、VOC、甲烷等气体，另外可扩展三甲胺、甲硫醇、甲硫醚、二甲二硫、二硫化碳、苯乙烯等恶臭指标以及PM2.5、PM10、温度、湿度等环境，可广泛用于垃圾转运站、污水处理厂、垃圾处理厂、化工园区、医药车间、城市街道、厂界等行业使用"
        ]
      },  {
        id: "022",
        name: "网格化微型空气检测站",
        dec: "网格化微型空气检测站",
        imgUrl: "portfolio-2.png",
        text: ["监测指标包括pm2.5,pm10,SO2,NO2,O3,CO,还可以根据客户需求进行扩展,如VOCs等。安装方式灵活多样,屋顶,平台,智慧灯杆等都可安装。动力系统可选择,既可完全太阳能供电；亦可接入市电运行。成本低,自产传感器可替代部分进口电化学传感器,成本可控。"]
      },  {
        id: "023",
        name: "智慧公交站",
        dec: "智慧公交站",
        imgUrl: "smart-bus-station.png",
        text: ["高度集成,体积小巧；可监测pm2.5,pm10,风力,风向,温度,湿度,大气压,紫外强度,辐射等多项气象参数（可选择）。可扩展SO2,NO2,O3,CO,VOCs等大气质量参数。可选无线网络或公交站有线网络等方式与后台通讯和数据传输。成本低,安装方便,只需接上公交站现有电源后简单固定即可。"]
      },  {
        id: "024",
        name: "空气质量监测展示平台",
        dec: "空气质量监测展示平台",
        imgUrl: "online-AQMS.png",
        text: ["大气质量实时动态管理。通过统计数据自动分析异常站点或时间段,方便管理部门精准管理,可设置条件自动预警和推送消息,还可定制化展示。"]
      },  {
        id: "025",
        name: "空气质量小程序",
        dec: "空气质量小程序",
        imgUrl: "miniprogram-AQMS.png",
        text: ["授权客户订阅后,通过微信小程序,可随时通过小程序快速获得当前的大气质量数据和历史数据。小程序可生产各种趋势和对比,方便用户进行快速分析和判断。当监测数据超出预设条件时,小程序会自动发送预警信息到授权客户的微信上,用户不再需要人工持续监测数据,从而将用户解放出来,降低工作符合。"]
      }
    ]
  // }, {
  //   type: '设备标定和校准',
  //   data: [
  //     {
  //       id: "041",
  //       name: "国家级网格化空气质量监测系统",
  //       dec: "国家级网格化空气质量监测系统",
  //       imgUrl: "gd-aisensing.png",
  //       text: ["国家级网格化空气质量监测系统,使用了功耗远低于市面上的金属氧化物气体传感器,同时保持了媲美电化学气体传感器的高灵敏度；通过阵列传感器实现多种气体的同时监测,并将数据实时上传至云端进行数据可视化处理,使得数据体现得更加直观,从而帮助空气检测人员进行判断。"]
  //     }
  //   ]
  // }, {
  //   type: '空气质量检测服务',
  //   data: [
  //     {
  //       id: "051",
  //       name: "环境监测服务",
  //       dec: "环境监测服务",
  //       imgUrl: "mini-air-monitors-station.jpg",
  //       text: ["通过阵列传感器实现多种气体的同时监测,并将数据实时上传至云端进行数据可视化处理。使用了功耗远低于市面上的金属氧化物气体传感器,同时保持了媲美电化学气体传感器的高灵敏度。"]
  //     }
  //   ]
  }, {
    type: '解决方案',
    data: [
      {
        id: "031",
        name: "环境监测解决方案",
        dec: "环境监测解决方案",
        imgUrl: "portfolio-1.png",
        text: ["大气环境治理首先要知道污染源现状,所以前期监测工作尤为重要。我们使用的是功耗远低于市面且灵敏度高的金属氧化物气体传感器,我们将这些传感器组成阵列传感器模组实现多种气体的同时监测,克服了传统传感器选择性低与易受干扰性气体及温湿度影响的缺点,极大降低现有气体传感器的成本。"]
      }, {
        id: "032",
        name: "家用燃气报警解决方案",
        dec: "家用燃气报警解决方案",
        imgUrl: "gas.png",
        text: ["目前国内家庭用可燃气体产品中占主流市场的主要是测0-10000ppm,市政燃气公司主要采用催化元件,主要测100%LEL。我们使用超高的灵敏度极大提高氢能生产,运输,储存和应用的安全监控的可靠性,保障企业,居民和用户的安全。理论计算寿命长达10年以上。体积小（可选插件封装或MEMS封装。成本低,抗干扰力强,可根据客户需求定制量程。"]
      }, {
        id: "033",
        name: "酒精检测解决方案",
        dec: "酒精检测解决方案",
        imgUrl: "alcohol.png",
        text: ["酒精检测仪一般用于交通单位，用来查询酒驾，整顿交通出行环境，加强道路安全。此外，酒精检测仪还适用于车间作业、井下作业、建筑行业等高危工作环境中的工作人员，以加强工作环境安全性。"]
      }
    ]
  }
]
const lastNews = [
  {
    date: '2024-12-13',
    title: '喜讯│艾感科技荣获第二届佛山高价值专利成果转移转化大赛铜奖',
    text: '2024年12月12日至13日，第二届佛山高价值专利成果转移转化大赛（以下简称“高转赛”）决赛将在佛山举行。艾感科技的新型MEMS甲烷传感技术及天燃气安全产品系统研究结合了先进的材料技术和智能传感器技术。其核心在于利用MEMS技术，开发出高灵敏度、快速响应的甲烷传感器。在本次决赛中荣获铜奖，充分展示了其在天然气安全领域的重要突破和潜力。',
    imgUrl: 'match.jpg'
  }, {
    date: '2023-04-26',
    title: '艾感科技获国务院港澳事务办公室主任肯定',
    text: '国务院港澳事务办公室主任、第十三届全国政协副主席、夏宝龙前往香港科技大学调研期间,范智勇教授向政协副主席详细介绍了香港科技大学的“先进显示与光电子技术国家重点实验室”的研发设备和科研实力,也向政协副主席详细介绍了“艾感科技”公司的技术和相关情况。当得知艾感公司已取得高新技术企业认证和多项授权专利、且产品在同行业技术领先时,国务院港澳事务办公室主任给予了充分肯定。',
    imgUrl: 'news-HKAMAO.jpg'
  }, {
    date: '2023-04-26',
    title: '王曦副省长对艾感科技表示支持',
    text: '广东省副省长、中国科学院院士王曦与广东省科技厅的一众领导前往香港科技大学调研。王曦副省长一行参观“先进显示与光电子技术国家重点实验室”时,范智勇教授向莅临人员详细介绍了“艾感科技公司”的团队、科研成果等情况。范智勇教授介绍,艾感公司是国家高新技术企业,拥有多项发明专利,产品和技术能广泛应用于多种场景,仿生嗅觉传感器甚至能应用于中医领域辅助诊断。王曦副省长对我司的气体传感器可以用在望闻问切这种疾病诊断方面很感兴趣,并表示全力支持我司在气体传感器领域的发展。',
    imgUrl: 'news-GDvice.jpg'
  }, {
    date: '2023-04-27',
    title: '佛山市高新区对艾感科技表示支持',
    text: '佛山市高新区禤科长莅临“艾感科技”实地走访,通过现场监控观看了NHC传感器的制备情况,参观了604的测试间。期间,听取工程师介绍“NHC半导体气体传感器”的先进性以及NHC传感器在网格化空气监测系统中的应用情况。禤科对我司的产品表示认同,认为我司产品的技术优势和成本优势巨大,并表示高新区愿意在不同领域帮助艾感对接更多的资源。',
    imgUrl: 'news-FS.jpg'
  }, {
    date: '2023-04-18',
    title: '艾感科技董事长范智勇教授参加「中国集成电路制造年会暨供应链创新发展大会智能传感器专题论坛」',
    text: '范智勇教授代表“艾感科技（广东）有限公司”、香港科大智能传感器与环境技术中心、香港科大先进显示与光电子技术国家重点实验室参加了由“中国集成电路联盟”主办的“中国集成电路制造年会暨供应链创新发展大会智能传感器专题论坛”。会上,范智勇教授重点介绍了“基于三维微纳结构的仿生光电与传感器件”的发展前景以及器件技术先进性与成本优势。',
    imgUrl: 'news-CICD.jpg'
  }, {
    date: '2022-12-16',
    title: '恭喜艾感科技董事长范智勇教授荣获「科学探索奖」',
    text: '2022年9月,第四届“科学探索奖”获奖名单正式揭晓,艾感科技董事长范智勇教授荣登榜单。今年奖项的提名、推荐、评审过程中,有超过900位各国院士、30多所知名高校校长参与。范智勇教授现为香港科技大学的终身教授,也是香港青年科学院创院院士,在十余年间取得了显著的成就：已获得超过3,000万港元的研究资助；在nature等的顶级期刊发表了超过220篇研究论文,被引用超过24,000次；在2018年底联合成立艾感科技（广东）有限公司,实现仿生嗅觉全面数字化、机器化。',
    imgUrl: 'news-explorer-price.jpg'
  }, {
    date: '2022-12-16',
    title: '热烈祝贺艾感科技董事长—范智勇教授荣获首届「中银香港科技创新奖2022」',
    text: '2022年12月,艾感科技董事长—范智勇教授荣获首届「中银香港科技创新奖」新材料新能源奖项,中银科创奖鼓励科创者瞄准世界科技前沿、对接国家重大战略需求、促进社会经济民生发展。范智勇教授研究成果颇丰,致力于开发新型微纳电子及光电子材料用于各种高性能光电器件和仿生器件,现为香港青年科学院创院院士,英国皇家化学会会士,IEEE 高级会员,香港科大智能传感器中心创始主任,粤港澳智能微纳光电联合实验室副主任,电子与计算机工程学系研究生事务主任。',
    imgUrl: 'news-bochk.jpg'
  }, {
    date: '2021-03-27',
    title: '副国级领导参观艾感展台',
    text: '2021年3月27日,全国政协副主席、民革中央常务副主席郑建邦一行在广东省政协副主席袁宝成,广东省佛山市委副书记、市长朱伟的陪同下莅临佛山市博士和博士后创新创业孵化基地参观考察,陪同考察活动的还有民革广东省委会主委程萍,佛山市政协副主席、民革佛山市委会主委唐冬生,佛山市人民政府秘书长张开机,佛山市禅城区区委书记黄少文,中国政法大学校董王平等相关领导。佛山市博士后管理办公室副主任张东航向郑建邦副主席一行介绍了佛山市博士和博士后创新创业孵化基地的基本建设情况,在基地展示厅,艾感科技（广东）有限公司——网格化微型空气质量监测站入驻项目代表进行了项目成果演示,得到郑主席和市委领导的高度评价和赞许。',
    imgUrl: 'news-Leader.jpg'
  }, {
    date: '2021-11-02',
    title: '艾感第二轮融资',
    text: '2021年下半年,艾感科技完成PreA轮融资,由深圳立创电子领投。深圳市立创电子商务有限公司运营的立创电子元器件商城,以下简称立创商城,是嘉立创集团旗下的子公司。立创商城拥有70,000多平米现代化元器件仓库,现货库存超200,000种,专业从事电子元器件零售和小批量采购,旨在打造中国最大的电子元器件一站式采购平台。商城电子元器件种类齐全,客户选购简单,和传统采购方式相比这种模式要简单的多,省去了查找种类和语音沟通的障碍,客户自助下单,在线支付或快递代收,方便快捷。产品价格对比功能,充分帮助采购人员评估产品。立创电子的被投企业包括列拓科技、南京绿芯、和上海集迦等芯片公司。',
    imgUrl: 'news-second-financing.jpg'
  }, {
    date: '2021-06-01',
    title: '艾感第一轮融资',
    text: '2021年上半年,艾感科技完成首轮融资,由香港HKX基金领投。香港HKX基金由红杉中国全球执行合伙人、十三届全国政协委员沈南鹏先生,大疆创新董事长、香港科技大学李泽湘教授,及香港大学陈冠华教授,联合22位香港知名教授及科技精英于香港创办。HKX基金专注投资香港及粤港澳大湾区早期科技领域初创企业,聚焦领域包括大数据及人工智能、微电子及芯片设计、机器人及智能制造、生物科技、医疗医药、先进材料及金融科技等。HKX基金的被投企业包括思特威,EcoFlow（正浩）,松灵机器人,博志生物等。',
    imgUrl: 'news-first-financing.jpg'
  }
]

const oldNews = [
  {
    date: '2024-10-09',
    title: '燃气隐患猛如虎，气体传感器来守护！',
    text: '甲烷气体传感器采用先进的生产工艺，它的使用寿命长，长期性能稳定，响应迅速，能够在极短的时间内检测到燃气泄漏，并发出警报。氢气传感器检测极限可以达到10ppb(0.01ppm),超 高的灵敏度极大提高氢能生产,运输,储存和应用的安全监控的可靠性,保障企业,居民和用户的安全。',
    imgUrl: 'news-introduce-6.jpg'
  }, {
    date: '2024-09-30',
    title: '气体传感器在环保行业的应用',
    text: '恶臭在线检测系统可同时检测氨、硫化氢、VOC、甲烷等气体，另外可扩展三甲胺、甲硫醇、甲硫醚、二甲二硫、二硫化碳、苯乙烯等恶臭指标以及PM2.5、PM10、温度、湿度等环境。可广泛用于垃圾运转站、污水、垃圾处理厂等。以智慧环境检测仪为核心,微型站可无线通讯将监测数据汇集到“云平台”。通过大量网格化布点，实时掌握区域内污染物的时空分布，识别重点污染源，可有针对性的降低重点地区污染物的排放情况，达到改善整个区域的环境质量的目的。',
    imgUrl: 'news-introduce-4.jpg'
  }, {
    date: '2024-09-25',
    title: '电子鼻：探索气味世界的神奇工具',
    text: '电子鼻是一种能够模拟人类嗅觉功能的设备。它由一系列化学传感器组成，每个传感器对不同的气味成分有不同的反应。当气味样品进入电子鼻时，传感器会根据与气味分子的相互作用产生不同的信号，通过复杂的算法处理这些信号，最终识别出气味的种类和浓度。在食品安全，医疗诊断，环境监测，农业领域都有相应的作用。',
    imgUrl: 'news-feet.jpg'
  }, {
    date: '2020-12-03',
    title: '南庄镇大气监测二期开发取得阶段性成果',
    text: '正式进入测试安装阶段,二期开发,实现了6参数实时监控,包括：SO2、NO2、PM10PM2.5、CO以及O3。二期主要安装在马路边灯柱上,此举更能准确监控马路边的污染情况。二期有9个监测站点,分别部署在季华-禅港、季华-紫洞、广台-紫洞、南庄-陶兴、南庄-龙津、吉利-龙津、广台-禅港、南庄-紫洞、罗南-禅港这一带。',
    imgUrl: 'news-introduce-5.jpg'
  }, {
    date: '2020-11-13',
    title: '南庄镇大气污染防治智能化监控',
    text: '南庄镇大气污染防治智能化监控微信小程序正式上线,只要您手机在身边,就可以随时随地查看南庄镇的空气情况。同样实现来实时监控空气中SO2、NO2、PM10、PM2.5的浓度,站在在线离线警告、污染警告等功能。可扫描前方二维码,或者微信搜索“南庄大气”小程序,便可以体验啦！',
    imgUrl: 'news-introduce-3.jpg'
  }, {
  //   date: '2020-09-27',
  //   title: '智能鞋垫',
  //   text: '智能鞋垫上线啦,赶快来感受一下吧！以专业运动鞋垫的标准对材质和结构进行深度定制,整体使用PU和硅胶拼接而成强大的缓冲能力,可以保障用户剧烈运动时给予足部足够的保护。可以下载安卓App/ios App,进行蓝牙连接,赶快过来体验吧！',
  //   imgUrl: 'news-introduce-4.jpg'
  // }, {
    date: '2020-08-19',
    title: '南庄镇空气质量检测平台',
    text: '2020年8月11日,南庄镇空气质量检测平台取得阶段性成果,正式进入测试阶段。主要是实时监控空气中SO2、NO2、PM10、PM2.5的浓度,以及实时风速风向,并有污染物质浓度的小时平均数据曲线图和AQI指数曲线图。2020年8月19日,在佛山市禅城区南庄镇环监所正式投入使用。',
    imgUrl: 'news-introduce-1.jpg'
  }, {
  //   date: '2020-06-18',
  //   title: '纳米熔喷布',
  //   text: '纳米纤维膜的丝有孔径小、呼吸阻力小等优点,完全拦截PM2.5颗粒和金黄色葡萄球菌等细菌,不随环境条件和储存时间而衰减。可以根据客户的要求提供90%,95%,99%等不同过滤率的纳米膜,同时也可帮客户将低效熔喷布或无纺布加工成95%-99%的高效滤材。',
  //   imgUrl: 'news-introduce-6.jpg'
  // }, {
    date: '2020-04-08',
    title: '新型纳米气体传感器',
    text: '三维纳米半导体气体传感器具有高灵敏度低功耗的特点,利用传感器阵列及人工智能算法实现多气体识别,优点包括寿命长、成本低等特点。主要用于环境污染性及危险性气体的低浓度高灵敏监测。可实现多种气体的同步实时监测,极大降低传感器成本,适合大规模使用,实现对环境气体网格化无盲点的监测覆盖。',
    imgUrl: 'news-introduce-3.jpg'
  }
]
const jobList = [
  {
    name: '生产总监',
    contentList: [
      {
        title: "岗位职责",
        con: [
          "1、目标制定：负责与技术团队、销售团队和财务团队对接，参与制定事业部年度经营计划、预算方案，参与事业部重大财务、人事、业务问题的决策，并提出建议；",
          "2、计划执行：指导PMC制定生产计划工作，根据生产进度，统筹协调各车间的生产，组织分配人力，平衡设备、材料调度工作；",
          "3、物料管控：根据物料计划，监督采购供应计划落实，控制材料库存、来料质量、到货及时率情况，及时协调解决生产瓶颈物资；",
          "4、品控与优化：负责协助技术部的技术创新和质量保证工作，发现问题及时组织解决和处理，重大问题报总经理协商处理，保证产品的良品率，并持续优化提高，负责产品的品质检测，及不良品的处理；",
          "5、外发协调：指导外发厂家质量效率管理工作，确保外发工厂价格合理、质量合格，高效生产工作；",
          "6、进度管理：组织和完善生产指挥系统，检查生产工作，确保生产任务的达成。每周一次定期召开生产例会，分析生产形势，提出解决问题的办法和措施，保证各项生产技术指标的全面完成；",
          "7、安全管理：负责EHS工作，发现问题，立即督促整改；",
          "8、成本管理：全面负责精益生产推进，降低生产成本工作；",
          "9、设备管理：负责设备管理，提高设备完好率和利用率；",
          "10、绩效管理：评判生产人员的工作绩效；",
          "11、库存管理：组织产品的包装和物流，负责库存管理；",
          "12、工艺管理：改进生产工艺，提高生产效率；",
          "13、扩产管理：根据需要，规划新产线的购置与建设；",
          "14、协调管理：按照总经理的安排，协调技术团队、生产团队、和公司日常管理。"
        ]
      }, {
        title: "任职要求",
        con: [
          "1、211以上本科，电子、材料、化学等理工科相关专业，有管理类专业经历者优先；",
          "2、5年以上相关行业生产管理协调经验，至少3年生产管理工作经验，熟悉生产过程，有一定原材料的供应渠道；",
          "3、熟悉生产规程以及质量标准，具备良好的生产经营管理理念，有一定财务与法务知识；",
          "4、熟悉团队建设，熟悉工厂规划、场地布局、生产计划制定；",
          "5、具备战略管理能力、沟通协调的能力以及资源整合的能力，有全局观，能换位思考，抗压能力强；",
          "6、有4级以上英文水平；",
          "7、有硕士或博士学位者优先；",
          "8、有管理类证书者优先；"
        ]
      }, {
        title: "福利待遇",
        con: [
          "1、工作地点 佛山市禅城区张槎街道华兴路22号顺盈嘉智能科技园C座八楼C801",
          "2、五险一金",
          "3、年薪 30w~60w，根据经验浮动",
          "4、有月绩效工资和年终奖，具体金额以任务完成情况进行衡量",
          "5、配期权"
        ]
      }
    ]
  }, {
    name: '硬件工程师',
    contentList: [
      {
        title: "岗位职责",
        con: [
          "1、负责空气监测站产品单板的硬件设计、调试等工作；",
          "2、维护现有产品，解决遗留问题；",
          "3、协助传感器、数据库等部门完成产品测试等工作。"
        ]
      }, {
        title: "任职要求",
        con: [
          "1、电子、电气、机电等相关专业，1-2年相关工作经验；",
          "2、能够独立完成硬件方案定型、器件选型、原理图与PCB设计、调试、测试维护工作，有单片机固件编写经验；",
          "3、至少熟悉一种电路设计软件；",
          "4、熟练使用常用的仪器，熟练使用电烙铁等工具；",
          "5、积极主动，勤奋好学，注重团队协作。"
        ]
      }, {
        title: "福利待遇",
        con: [
          "1、 根据工作经验，10K/月 – 20K/月",
          "2、 买社保",
          "3、 每年一次培训机会",
          "4、 年底双薪"
        ]
      }
    ]
  }, {
    name: '软件工程师',
    contentList: [
      {
        title: "岗位职责",
        con: [
          "1、负责微信小程序及手机APP开发（Android和iOS）、上线发布；",
          "2、负责PC应用平台实时数据采集软件开发；",
          "3、负责软件的维护与升级；",
          "4、参与公司产品讨论，编写设计、开发及实现文档。"
        ]
      }, {
        title: "任职要求",
        con: [
          "1、有丰富的PC软件和移动端开发经验；",
          "2、熟悉C++或Java语言， Qt、Android studio等软件开发环境；",
          "3、熟悉物联网应用协议，低功耗蓝牙、NB-IOT等设备调试；",
          "4、逻辑思维能力强，了解JS，熟练配合后台开发的程序整合；",
          "5、适应灵活的工作时间，随时对软件进行维护；",
          "6、熟悉vue，阿里G2，百度echart，JS,JQ微信小程序开发模式；",
          "7、熟悉springboot,Mybtais,javase,MQtt,AndroidUI,Framework,开发串口开发协议；",
          "8、熟悉mysql/mongodb等sql非sql数据库；",
          "9、熟悉ObjectiveC语言特性，了解HTTP/TCP协议。"
        ]
      }, {
        title: "福利待遇",
        con: [
          "1、 根据工作经验，10K/月 – 20K/月",
          "2、 买社保",
          "3、 每年一次培训机会",
          "4、 年底双薪"
        ]
      }
    ]
  }
]
const footLinkList = [{
        url: "http://gd.aisensing.cn/",
        name: "国家级网格化空气质量监测系统"
      }, {
        url: "http://nzair.aisensing.cn/",
        name: "禅城区南庄镇空气质量监测平台"
      }, {
        url: "http://nzaqi.aisensing.cn/",
        name: "老版南庄监控平台"
      }, {
        url: "http://nzdata.aisensing.cn/",
        name: "南庄大气微型站管理系统"
      }, {
        url: "http://nzserve.aisensing.cn/",
        name: "中山大学监控平台"
      }, {
        url: "http://nz.aisensing.cn/",
        name: "南庄镇大气质量智能化监控平台"
      }, {
        url: "http://hk.aisensing.cn/",
        name: "香港科大数据后台"
      }, {
        url: "http://web.aisensing.cn/",
        name: "艾感新版大气监测后台"
      }, {
        url: "http://admin.feelt-sport.com/",
        name: "智能鞋垫后台管理系统"
      }, {
        url: "http://tpapi.aisensing.cn/",
        name: "瀚蓝恶臭管理后台"
      }, {
        url: "http://nai.aisensing.cn/",
        name: "柯内特管理后台"
}]
const intellectualList = [
  {
    url: imgUrl.intellectual11,
    title: "知识产权管理体系认证证书",
    date: '2024-06'
  }, 
  {
    url: imgUrl.intellectual12,
    title: "一种三维纳米管气体传感器阵列及其封装方法",
    date: '2023-09'
  }, {
    url: imgUrl.intellectual13,
    title: "一种气体传感器阵列及其打印溶液调制方法",
    date: '2023-08'
  }, {
    url: imgUrl.intellectual14,
    title: "一种基于环境变化的自启动净化装置",
    date: '2023-08'
  }, {
    url: imgUrl.intellectual15,
    title: "一种气体传感器阵列的打印控制方法及其打印装置",
    date: '2023-04'
  }, {
    url: imgUrl.intellectual16,
    title: "燃气报警器",
    date: '2023-04'
  }, {
    url: imgUrl.intellectual17,
    title: "空气质量检测仪",
    date: '2023-04'
  }, {
    url: imgUrl.intellectual18,
    title: "一种净化装置",
    date: '2022-11'
  },{
    url: imgUrl.intellectual19,
    title: "基于纳米纤维膜的空气质量监测传感器及监测系统",
    date: '2022-11'
  }, {
    url: imgUrl.intellectual20,
    title: "一种检测气体的校准方法及系统",
    date: '2022-10'
  }, {
    url: imgUrl.intellectual5,
    title: "一种校准装置",
    date: '2022-05'
  }, {
    url: imgUrl.intellectual1,
    title: "一种喷墨打印制备气体传感器阵列的方法",
    date: '2022-01'
  }, {
    url: imgUrl.intellectual4,
    title: "一种空气防尘管理系统及空气质量检测系统",
    date: '2021-11'
  }, {
    url: imgUrl.intellectual3,
    title: "一种小型自供电无线气体传感器盒及传感器阵列",
    date: '2021-05'
  }, {
    url: imgUrl.intellectual7,
    title: "大气质量检测官方版APP",
    date: '2021-05'
  }, {
    url: imgUrl.intellectual8,
    title: "国家级空气质量监测系统1.0",
    date: '2021-05'
  }, {
    url: imgUrl.intellectual9,
    title: "南庄空气检测站管理平台",
    date: '2021-05'
  }, {
    url: imgUrl.intellectual10,
    title: "大气污染防治智能化监控微信小程序1.11",
    date: '2021-05'
  }, {
    url: imgUrl.intellectual2,
    title: "一种用于阵列型模拟传感器的防交叉响应读取电路",
    date: '2020-12'
  }, {
    url: imgUrl.intellectual6,
    title: "计算机软件著作权登记",
    date: '2019-10'
  }]
const honorList = [
  {
  //   urlUp: imgUrl.honor9,
  // }, {
    urlUp: imgUrl.honor9,
    urlDown: imgUrl.honor1,
  }, {
    urlUp: imgUrl.honor3,
    urlDown: imgUrl.honor4,
  }, {
    urlUp: imgUrl.honor5,
    urlDown: imgUrl.honor6,
  }, {
    urlUp: imgUrl.honor7,
    urlDown: imgUrl.honor8,
  }, 
]
const aqiList = [
  {
    name: "SO2",
    a: "0-150",
    b: "150-500",
    c: "500-650",
    d: "650-800",
    e: "800<",
    f: "无"
  }, {
    name: "NO2",
    a: "0-100",
    b: "100-200",
    c: "200-700",
    d: "700-1200",
    e: "1200-2340",
    f: "2340<"
  }, {
    name: "CO",
    a: "0-5",
    b: "5-10",
    c: "10-35",
    d: "35-60",
    e: "60-90",
    f: "90<"
  }, {
    name: "O3",
    a: "0-160",
    b: "160-200",
    c: "200-300",
    d: "300-400",
    e: "400-800",
    f: "800<"
  }, {
    name: "PM2.5",
    a: "0-35",
    b: "35-75",
    c: "75-115",
    d: "115-150",
    e: "150-250",
    f: "250<"
  }, {
    name: "PM10",
    a: "0-50",
    b: "50-150",
    c: "150-250",
    d: "250-350",
    e: "350-420",
    f: "420<"
  }, {
    name: "AQI",
    a: "0-50",
    b: "50-100",
    c: "100-150",
    d: "150-200",
    e: "200-300",
    f: "300<"
  }
]
export default { productList, lastNews, oldNews, jobList, footLinkList, intellectualList, honorList, aqiList }